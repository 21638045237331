import React from 'react';

const WorkExperience = () => (
  <div className="flex container max-w-screen-lg m-auto px-4 flex-col mt-32">
    <div>
      <h2 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-400 sm:text-4xl sm:leading-10">
        Work Experience
      </h2>
    </div>

    <div className="mt-12 grid gap-16 border-t-2 border-gray-100 pt-12">
      <dl>
        <div className="md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Prose on Pixels (HAVAS)
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Head of Development, Global Technology
              <br />
              December 2023 - Present
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Working as part of the Global Technology team to deliver platforms which ensure a unified technology across a new production network.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Developing systems which aid with project management, finance, resourcing, approvals and Digital Asset Management (DAM).
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Creating and maintaince of AI tools which allow for content automation in a fast paced and constantly evolving production landscape.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              HAVAS LYNX Group
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Head of Development
              <br />
              July 2015 - December 2023
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Leading the development of a multi-discipline team; working with internal and external parties to create and manage product/project roadmaps by facilitating and attending client workshops, defining and creating statements of work, leading architectural conversations, managing the day to day to ensure projects are delivered against their commitments and reporting the team’s progress.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Ensuring a consistent technical approach and environment by introducing and leading initiatives for the development team to adopt. Examples include the establishment of a technology radar, ensuring a consistent use of AWS utilising Pulumi and establishing training opportunities for the team.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Line management responsibility for multiple members of the development team. Conducting regular 1-to-1s, creating and aligning PDPs and wellness check-ins. Fostering an inclusive team culture geared towards success. Mentoring junior members of the team.
            </p>
            <p className="text-base leading-6 text-gray-500">
              Hands on development, using technologies such as; React, Node.js, PHP and AWS.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              ao.com
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Senior Frontend Web Developer
              <br />
              April 2014 - July 2015
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Working in an agile environment to develop and maintain the ao.com web experience, including; ao.com, ao.com/life, client websites and internal projects. Working along side a multi-discupline team consisting of; UI, design, SEO and QA teams.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Architecting and building new website functionality as part of the web stream team while ensuring a seamless experience across multiple platforms/devices and browsers.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Keeping abreast of the latest trends and technologies to ensure the best performance is achieved for all users of the ao.com website.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Working with Angular.js to power the mobile website in a test-driven solution which utilising Jasmine.
            </p>
            <p className="text-base leading-6 text-gray-500">
              Achievements while working at ao.com include; achieving Sitecore XP 7 Certification, creating and integrating a responsive framework with the existing website and integrating a gulp build agent to the website.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Fluid Creativity
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Backend Developer
              <br />
              June 2011 - April 2014
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Custom development and theming for open source software such as Magento, Joomla and Wordpress. As well as using a Zend framework based platform to create custom solutions.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Working in co-operation with creative and SEO teams to translate PSD designs to semantic and optimised HTML and CSS which is compatible with all major browsers on both Mac/PC, tablets and mobile devices.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Working in a team of developers and independently to provide full service from requirement gathering to deployment solutions.
            </p>
            <p className="text-base leading-6 text-gray-500 mb-3">
              Meeting with clients on a regular basis to inform of progress and also to complete training on various content management systems.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Tiny Rocketship
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Web Developer
              <br />
              July 2010 - January 2011
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500 mb-3">
              Responsible for developing websites from a layered PSD utilising HTML, CSS and
              jQuery combined with an in-house developed CMS.
            </p>
            <p className="text-base leading-6 text-gray-500">
              Maintaining and creating new functionality on the in-house CMS as well as
              training and advising new and existing clients.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              ASDA
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Checkout Operator
              <br />
              November 2008 - December 2010 (Part-time)
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Interacting with customers while following strict guidelines to ensure excellent
              custom service. Working as part of the Asda team to meet store targets.
            </p>
          </dd>
        </div>
        <div className="mt-8 border-t border-gray-800 pt-6 md:grid md:grid-cols-12 md:gap-8">
          <dt className="md:col-span-5">
            <h3 className="text-base leading-6 font-medium text-gray-400 ">
              Co-operative Financial Services
            </h3>

            <p className="text-base leading-6 text-gray-500">
              Checkout Operator
              <br />
              Octover 2005 (Work experience)
            </p>
          </dt>
          <dd className="mt-2 md:mt-0 md:col-span-7">
            <p className="text-base leading-6 text-gray-500">
              Working in various departments undertaking different departmental tasks while
              working on my own initiative. Following instructions accurately while sticking
              to strict deadlines.
            </p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
);

export default WorkExperience;
